@media (max-width: 1700px) {
  .featured-salon .owl-carousel .owl-stage {
    right: 242px;
  }
}

@media (max-width: 1600px) {
  .laptop-view {
    margin-top: 80px;
  }

  .banner-img {
    height: calc(100vh - 60px);
  }

  .treatment-head-txt p {
    font-size: 20px;
  }
  .common-heading {
    font-size: 40px !important;
  }
  .rated-business-txt p {
    font-size: 20px;
  }
  .industry-blogs-txt p {
    font-size: 20px;
  }
  .Sign-main-text p {
    font-size: 18px;
  }

  .Terms-Use-box p {
    color: #666;
    font-size: 16px !important;
  }

  .Terms-Use-box {
    padding: 60px 60px !important;
  }
  .review-card-imgs .after-img-bottom-icon {
    bottom: -20px;
  }

  .brand-logos-box img {
    width: 125px;
  }
  .brand-logos-box {
    height: 180px;
    width: 210px;
  }
}

@media (max-width: 1500px) {
  .login-top a {
    font-size: 16px !important;
  }
  .side-treatment .owl-nav .owl-prev span::before {
    left: -40px;
  }
  .side-treatment .owl-nav .owl-next span::before {
    right: -30px;
  }
  .side-treatment .slick-prev::before {
      left: -40px;
  }
  .side-treatment .slick-next::before {
    right: -30px;
  }
  .right-set {
    width: calc(100% - 15%);
    margin-left: unset;
    
  }
  .featured-salon-text {
    width: calc(100% - 30%);
    margin: auto;
  }

  .find-salon-text h1 {
    font-size: 30px;
  }
  .services-types select {
    padding: 8px 12px;
  }
  .select-city-input input {
    padding: 8px 12px;
  }
  .sreach-city img {
    background-color: #b9b5ab;
        padding: 6px 8px;
  }
  .treatment-head-txt h2 {
    font-size: 40px;
  }
  .treatment-head-txt p {
    font-size: 16px;
  }
  .items-box p {
    font-size: 16px;
  }
  .featured-salon-h h2 {
    font-size: 40px;
  }
  .featured-salon-text h3 {
    font-size: 28px;
  }
  .featured-salon-text p {
    font-size: 16px;
  }
  .view-profile-feature a {
   
    font-size: 18px;
  
}
  .rated-business-txt h2 {
    font-size: 30px;
  }
  .rated-business-txt p {
    font-size: 16px;
  }
  .rated-business-box p {
    font-size: 16px;
  }
  .clients-say-right h2 {
    font-size: 40px;
  }
  .clients-say-text p {
    font-size: 16px;
    /* font-weight: 100; */
  }
  .clients-p-name h3 {
    font-size: 20px;
  }
  .industry-blogs-txt h2 {
    font-size: 30px;
  }
  .industry-blogs-txt p {
    font-size: 16px;
  }
  .left-side-blog h2 {
    font-size: 24px;
  }
  .right-side-blog-text h3 {
    font-size: 18px;
  }

  .Sign-main-text h2 {
    font-size: 30px;
  }

  .Sign-main-text p {
    font-size: 14px;
  }

  .category-select label {
    font-size: 14px;
  }

  .category-select input {
    width: 20px;
    height: 20px;
  }

  .Salon-Signup-btn button {
    font-size: 16px;
  }

  .sign-detail-input label {
    font-size: 14px;
  }

  .sign-detail-input label::placeholder {
    font-size: 14px !important;
  }

  .common-heading {
    font-size: 30px !important;
  }
  .choose-plan-title {
    font-size: 24px !important;
    
}
.salon-logo-detials h2 {
    font-size: 30px;
 
}
.review-card-name p {
    font-size: 22px;
    
}
  .laptop-view {
    margin-top: 80px;
  }
  .salons-card-details h3 {
    font-size: 25px;
  }
  .salons-card-details .Futura-Lt-BT {
    font-size: 16px;
  }
  .Salon-Detail-banner h3 {
    font-size: 35px;
  }
  .Salon-Detail-banner p {
    font-size: 16px;
  }
  .blog-text {
    font-size: 20px;
  }
  .contact-card-box p {
    font-size: 16px;
   
}
.contact-card-box h3 {
    font-size: 25px;
}
.price-rate h3 {
    
    font-size: 45px;
   
}
.what-included h2 {
   
    font-size: 20px;
    
}
}

@media (max-width: 1399.98px) {
  .we-recommend-box {
    padding: 60px 40px;
  }
  .review-card-imgs .after-img-bottom-icon {
    bottom: 0px;
  }
  .blog-top-slide a {
    width: 30% !important;
  }
  .button-inner {
    margin-right: 5px;
  }
  .featured-salon .owl-carousel .owl-stage {
    right: 213px;
  }
}
@media (max-width: 1300px) {
  .side-treatment .owl-nav .owl-prev span::before {
    left: -30px;
  }
   .side-treatment .slick-prev::before {
      left: -30px;
  }

}

@media (max-width: 1199.98px) {
  .salons-card-btn a {
    min-width: 160px;
  }

  .salons-card-details .Futura-Lt-BT {
    font-size: 15px;
  }
  .salons-card-details h3 {
    font-size: 25px;
  }

  .salons-card-contact p {
    font-size: 12px;
  }

  .salons-card-contact p {
    margin: 0;
    font-size: 12px;
  }

  .salons-card-contact span {
    margin-right: 2px;
  }

  .salons-card-details .Futura-Lt-BT {
    font-size: 15px;
    margin-bottom: 5px;
  }
  .review-card {
    flex-direction: column;
  }
  .review-card-imgs {
    width: 100%;
  }
  .review-card-text {
    width: 100%;
    padding: 0;
  }
  .review-card-imgs .after-img span p {
    left: 13px;
  }
  .review-card-imgs .before-img span p {
    left: 13px;
  }
  .review-card-imgs .after-img-bottom-icon {
    bottom: -20px;
  }
  .follow-icon-contin {
    width: 40%;
  }
  .button-inner span {
    width: 130px;
    height: 40px;
    font-size: 15px;
  }
  .button-inner {
    width: 130px;
    height: 40px;
  }
  .offered-treatments .treatment-box {
    width: 200px;
  }
  .featured-salon .owl-carousel .owl-stage {
    right: 182px;
  }
  .buisness_form_fields p {
  
    width: 105px;
}
.buisness_form_fields .form-select {
   
    width: 30%;
}
}

@media (max-width: 991.98px) {
  .brand-logos-box {
    height: 180px;
    width: 100%;
  }
  li.mob-show {
    display: block!important;
}
.last-menu button {
    margin: 5px 8px;
    font-weight: 100;
    text-decoration: none;
    font-size: 14px;
    border: none;
    color: #000;
    background: none;
}
  .choose-plan-box .Salon-Signup-btn {
    margin-top: 0px;
  }
  .choose-plan-title {
    margin-bottom: 0px !important;
  }
  .right-side-mail-send {
    width: 100% !important;
    margin: 30px 0px;
  }
  .right-side-blog-list.mb-4.mobile_view img {
    width: 100%;
    object-fit: cover;
    height: 320px;
}
.right-side-blog-list.mb-4.mobile_view {
  display: flex;
  cursor: pointer;
  flex-wrap: wrap;
}
  .left-side-blog {
    margin-bottom: 50px;
  }
.featured-salon .slick-prev:before {
 display: none;
}

.featured-salon .slick-next:before {
  display: none;
}

  .rated-business-box {
    text-align: center;
    margin-bottom: 40px;
  }

  .right-side-blog {
    margin-left: 0px;
  }

  .featured-salon-text {
    padding-left: 0px;
  }
  .toggle-icon {
    display: block;
    cursor: pointer;
  }
  .navbar-bottom ul {
    display: flex;
    list-style: none;
    flex-direction: column;
    padding: 0;
    margin: 0;
    position: fixed;
    padding: 90px 0;
    width: 215px;
    left: -220px;
    transition: 0.5s ease;
    background: #fff;
    top: 0;
    z-index: 999;
    bottom: 0;
  }
  .social-icons-head {
    display: none;
  }

  .mobile-menu {
    left: 0 !important;
  }
  .mobile-menu li a {
    color: #000 !important;
  }
  .navbar-bottom ul li a {
    color: #fff;
    margin: 10px 15px;
    font-weight: 100;
    display: block;
    text-decoration: none;
  }
  .navbar-bottom {
    padding: 0;
  }
  .side-treatment .owl-nav .owl-prev span::before {
    left: 0 !important;
  }
  .side-treatment .owl-nav .owl-next span::before {
    right: 0 !important;
  }
 .side-treatment .slick-prev::before {
      left: 0;
  }
  .side-treatment .slick-next::before {
    right: 0;
  }
  .feature-star {
    display: none!important;
    position: unset;
  }

  .Sign-main-text p {
    width: 100%;
  }

 .sign-up-details-box {
    padding: 50px 30px 50px 30px;
}

  .bronze-box {
    margin-bottom: 40px;
  }
  .salons-card-btn a {
    min-width: 120px;
    font-size: 14px;
  }

  .rated-salons-card img {
    width: 25%;
  }

  .salons-card-contact img {
    width: 75% !important;
  }

  .Salon-Detail-banner h3 {
    font-size: 30px;
  }

  .Salon-Detail-banner p br {
    display: none;
  }

  .Salon-Detail-banner p {
    font-size: 18px;
  }

  .follow-icon-contin {
    width: 60%;
  }
  .brand-user-logo .brand-logo-top {
    width: 60%;
  }
  .brand-user-logo img {
    width: 60%;
  }

  .blog-Next {
    width: 100%;
    justify-content: end;
  }
  .blog-Previous {
    width: 100%;
  }

  .support-view-left {
    text-align: center;
    margin-bottom: 30px;
  }
  .support-view-left img {
    width: 70%;
  }
  .support-view-right p {
    font-size: 16px;
  }
  .support-view-section {
    padding: 80px 0px;
  }
  .sign-profile.review-page-upld {
    margin-bottom: 30px;
  }

  .sign-profile.review-page-upld {
    align-items: flex-start;
  }
  .review-page-upld .sign-upload-btn {
    width: 30% !important;
  }
  .Terms-Use-box {
    padding: 40px 40px !important;
  }
  .Review-Details-box {
    padding: 50px 50px;
  }
  .bronze-box.set-active {
    transform: scaleY(1);
  }
  .offered-treatments {
    display: flex;
    align-items: start;
    flex-direction: column;
  }
  .offered-treatments .treatment-box {
    margin: 10px 0px;
  }
  .simple-header.active {
    top: 0;
  }
  .simple-header.active .navbar-top {
    display: flex !important;
  }
  .last-menu {
    position: unset;
  }
  .login-top {
    display: none;
  }
  .navbar-bottom .login-btn {
    display: block;
  }
  .navbar-bottom .login-btn a {
    color: #fff !important;
  }
  .navbar-bottom .login-btn a {
    color: #fff !important;
    width: 90% !important;
    padding: 10px;
    margin: 0 auto;
  }
  .email-box {
    width: 70%;
  }
  .simple-header.active .navbar-top {
    box-shadow: rgba(100, 100, 111, 0.07) 0px 7px 29px 0px;
  }
  .active .navbar-bottom {
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
  .simple-header.black-active .navbar-bottom {
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
  .featured-salon .owl-carousel .owl-stage {
    right: 0;
  }
  .owl-carousel .owl-item img {
    
    object-fit: cover;
  }
}

@media (max-width: 767.98px) {
  .treatment-head-txt h2 {
    font-size: 40px;
  }
  .rated-business-txt h2 {
    font-size: 40px;
  }
  .rated-business-txt p {
    color: rgba(7, 21, 35, 0.8);
    font-size: 16px;
    font-weight: 400;
    width: 100%;
    margin: auto;
  }
  .clients-say-right h2 {
    font-size: 40px;
  }
  .industry-blogs-txt h2 {
    font-size: 40px;
  }

  .featured-salon-h h2 {
    font-size: 30px;
  }
  .side-treatment .owl-nav .owl-prev span::before {
    display: none;
  }
  .side-treatment .owl-nav .owl-next span::before {
    display: none;
  }
 .side-treatment .slick-prev::before {
      display: none;
  }
  .side-treatment .slick-next::before {
    display: none;
  }
  .rated-salons-card img {
    width: 35%;
  }
  .common-heading {
    font-size: 30px !important;
  }
  .follow-icon-contin {
    width: 80%;
  }
  .aur-blog-box {
    padding: 40px 40px;
  }

  .blog-text {
    font-size: 20px;
  }
  .blog-card-box a {
    padding: 12px 12px;
    font-size: 16px;
  }

  .blog-Previous {
    margin-bottom: 20px;
  }

  .review-page-upld .sign-upload-btn {
    width: 45% !important;
  }

  .login-main-box {
    padding: 30px 30px;
  }

  .login-btn button {
    min-width: 260px;
    padding: 15px;
  }

  .salon-logo-detials h2 {
    font-size: 30px;
  }
  .Review-Details-box {
    padding: 50px 50px;
  }
  .account-tab ul {
    flex-direction: column;
  }

  .details-data-box {
    padding: 20px 40px;
  }
  .account-tab ul li {
    margin-left: 0px;
    justify-content: center;
  }
  .review-card-imgs {
    flex-direction: column;
  }
  .review-card-imgs .before-img {
    margin-bottom: 20px;
  }
  .review-card-imgs .after-img {
    margin-bottom: 20px;
  }

  .review-card-imgs .before-img span p {
    left: 20px !important;
  }
  .review-card-imgs .after-img span p {
    left: 20px;
  }
  .profile-bio-descr {
    padding: 12px 15px;
  }
  .profile-bio-descr p {
    font-size: 14px;
  }
  .right-set {
    padding-right: 20px !important;
    padding-left: 20px !important;
    width: 100%;
  }

  .blog-card-box {
    flex-direction: column;
    text-align: center;
  }

  .blog-text-card {
    margin-left: 0px !important;
    margin-top: 20px;
  }
  .black-logo.blog-card-img {
    height: 240px;
    width: 100%;
  }

  .add-review-text {
    position: absolute;
    width: 100%;
    right: 0;
    bottom: 32px;
  }
  .add-review-text a {
    font-size: 20px;
  }
  .pagination-tab {
    width: 100%;
  }
  .account-tab ul .active-tab::before {
    display: none;
  }
.why-salon-listing {
    padding: 0 15px;
    height: 100%;
}
.why-salon-box {
   
    padding: 20px 20px;
}
.buisness_form_fields p {
    
    width: 60px;
}
}

@media (max-width: 575.98px) {
  .we-recommend-box {
    padding: 40px 30px;
  }
.certified-right img {
    width: 100px;
    height: 100px;
}
  .side-treatment.mt-5 {
    margin: 0 !important;
}
.Salon-detail-contact {
    padding: 40px 0;
}
.banner-img-salon {
 
   height: 100%;
        padding-top: 145px;
   
}
.clients-say {
   
    height: 570px;
   
}
.brand-user {
    padding: 15px 0px;
  
}
  .seco-footer-box ul {
    list-style: none;
    padding: 0;
  }
  .seco-footer-box {
    margin-left: 0;
    margin-top: 20px;
  }
  .treatment-head-txt h2 {
    font-size: 30px !important;
  }
  .rated-business-txt h2 {
    font-size: 30px !important;
  }
  .clients-say-right h2 {
    font-size: 30px !important;
  }
  .industry-blogs-txt h2 {
    font-size: 30px !important;
  }
  .select-city {
    display: flex;
    align-items: start;
    flex-direction: column;
  }
  .services-types {
    margin-bottom: 30px;
    margin-right: 0px;
    width: 100%!important;
  }
  .select-city-input {
    margin-right: 28px;
    margin-right: 0px;
    width: 100%!important;
  }
.sreach-city {
    margin-top: 20px !important;
    margin: auto;
}
  .featured-salon-h h2 {
    font-size: 30px !important;
  }
  .featured-salon-text h3 {
    font-size: 25px !important;
  }
  .featured-salon-text p {
    font-size: 16px !important;
  }

  .left-side-blog h2 {
    font-size: 25px !important;
  }
  .right-side-blog-text h3 {
    font-size: 20px !important;
  }
.select-city-main {
    max-width: 365px !important;
}
  .treatment-head-txt p {
    font-size: 20px !important;
  }
  .industry-blogs-txt p {
    font-size: 20px !important;
    width: 100% !important;
  }

  .rated-business-box img {
    width: 140px !important;
        margin-bottom: 10px;
  }

  .find-salon {
    flex-direction: column;
    align-items: center;
  }

  .find-salon img {
    margin: 0 0 20px 0;
  }

  .navbar-top {
    padding: 30px 20px 20px 60px !important;
  }

  .treatment-items .items-box img {
    width: 200px !important;
    object-fit: contain;
  }

  .treat-box-icon {
    height: 200px !important;
  }

  .side-treatment .owl-nav {
    display: none !important;
  }

  .featured-salon-text {
    width: 100% !important;
    margin: auto;
  }

  .featured-salon .owl-nav .owl-next span::before {
    width: 50px !important;
  }

  .featured-salon .owl-nav .owl-prev span::before {
    width: 50px !important;
    height: 70px !important;
    right: 90px !important;
  }

  .right-set {
    width: 100% !important;
  }

  .side-treatment .owl-carousel .owl-stage-outer {
    padding: 0px !important;
  }
  .clients-say {
    padding: 100px 0px;
  }
  .clients-say .owl-nav .owl-prev span::before {
    bottom: -20px;
  }
  .clients-say .owl-nav .owl-next span::before {
    bottom: -20px;
  }
.banner-img {
    height: 100%;
    padding: 30px 0;
}
  .laptop-view {
    margin-top: 0px;
  }
  .featured-salon .owl-nav {
    display: none !important;
  }
  .featured-salon .owl-dots {
    display: none;
  }
  .featured-salon-text {
    padding-top: 50px;
    padding-left: 18px;
    padding-right: 18px;
  }
  .find-salon-text h1 {
    font-size: 30px;
  }

  .sign-up-details-box {
    padding: 25px 10px !important;
  }

  .sign-profile {
    display: flex;
    align-items: start;
    flex-direction: column;
  }

  .sign-upload-btn {
    margin-left: 0px;
    margin-top: 20px;
  }
  .sign-upload-btn button {
    font-size: 14px;
  }

  .offer-tittle h2 {
    font-size: 25px;
  }

  .offer-tittle p {
    font-size: 14px;
  }
  .price-rate h3 {
    font-size: 40px;
  }

  .price-rate span {
    font-size: 16px;
  }

  .offer-tittle .yearli-bill {
    font-size: 14px;
  }

  .what-included h2 {
    font-size: 18px;
  }

  .what-included p {
    font-size: 15px;
  }

  .Salon-Signup-btn button {
    font-size: 16px;
  }
  .rated-salons-card {
    flex-direction: column;
    padding: 15px;
  }
 .salon-info {
    flex-direction: row;
    gap: 10px;
}

  .rated-salons-card img {
    width: 50%;
    margin-bottom: 20px;
  }

  .salons-card-details {
    margin-left: 20px;
  }

  .salons-card-btn {
    display: flex;
    flex-direction: unset;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
  }
  .salons-card-btn a {
    min-width: 47%;
    font-size: 14px;
    width: 47%;
    margin-bottom: 8px;
  }

  .salons-card-contact img {
    margin-bottom: 0 !important;
  }

  .contact-title p {
    font-size: 18px;
    width: 100%;
  }

  .livechat-box {
    padding: 30px 20px;
  }

  .Salon-Detail-banner-btn button {
    margin:0;
    width: 100%;
  }

.follow-icon-contin {
    width: 90%;
    flex-wrap: wrap;
}
.follow-social {
    padding: 30px 0px;
}
  .follow-icon-box {
    padding: 5px;
  }
  .aur-blog-box {
    padding: 20px 0px;
  }

  .support-view-left img {
    width: 90%;
  }
  .drop-us-line p br {
    display: none;
  }
  .drop-us-line p {
    font-size: 16px;
  }
  .drop-us-submit button {
    padding: 10px 40px;
    font-size: 16px;
  }

  .sign-profile.review-page-upld {
    align-items: center;
  }
  .review-page-upld .sign-upload-btn {
    width: 60% !important;
  }
  .common-heading {
    font-size: 20px !important;
  }
  .blog-detail-tittle p {
    font-size: 16px;
  }
  .salon-logo-detials h2 {
    font-size: 25px;
  }

  .salon-statics {
    margin-bottom: 40px;
  }

  .Review-Details-box {
    padding: 30px 30px;
  }
  .drop-us-line h2 {
    font-size: 25px;
  }
  .right-side-mail-send h2 {
    font-size: 20px !important;
  }
  .blog-text {
    padding-right: 0;
  }

  .contact-title-main {
    margin-top: 0px;
  }
  .review-box {
    padding: 30px 30px;
  }
  .Sign-main-text {
    margin-bottom: 35px;
  }
  .Sign-main-section {
    margin-top: 82px;
    padding: 30px 0px;
  }
  .Careers-box {
    padding: 30px 30px;
  }
 .Search-input-section {
    margin-top: 83px;
    padding-top: 50px;
    padding-bottom: 0;
}
.salons-card-details h3 {
    font-size: 22px;
}
.rated-salon-tittle {
    text-align: center;
    margin: 20px 0px;
}
  .rated-salons {
    padding: 20px 0px;
  }
  .Livechat-section {
    margin-top: 130px;
    margin-bottom: 60px;
  }
  .login-btn a {
    padding: 15px 90px;
  }
  .Login-main-section {
    margin-top: 142px;
  }
  .dashboard-banner {
    height: 40vh !important;
  }
  .rated-salons-scroll {
    padding: 10px 5px;
  }

  .blog-Next-text p {
    font-size: 15px;
    margin: 0;
  }
  .blog-Next-text .card-text {
    color: #071523;
    font-size: 14px;
    font-weight: 400;
  }
  .blog-Previous-text p {
    font-size: 15px;
    margin: 0;
  }
  .blog-Previous-text .card-text {
    font-size: 14px;
  }
  .category-select input {
    width: 15px;
    height: 15px;
  }
  .category-select {
    margin-bottom: 10px;
  }
  .what-included ul li {
    margin-bottom: 10px;
  }
  .what-included p {
    width: 100%;
  }
  .livechat-box h2 {
    height: 20vh;
  }
  .sign-detail-input input {
    font-size: 12px;
  }
  .tab-dots ul li p {
    font-size: 13px;
  }
  .review-rating h2 {
    font-size: 30px;
  }
  .review-rating p {
    font-size: 20px;
  }

  .your-plan-card {
    padding: 20px;
  }
  .plan-detail-box-two {
    border-right: 0;
    border-left: 0;
    padding: 0px !important;
  }
  .price-rate {
    justify-content: center;
  }
  .Your-Plan-box {
    padding: 40px 20px;
  }
  .bronze-box {
    padding: 25px 15px;
  }
  .popular-treatments {
    padding: 50px 0;
  }
  .featured-salon {
    padding: 50px 0;
  }
  .rated-business {
    padding: 50px 0;
  }
  .clients-say {
    padding: 65px 0px;
  }
  .industry-blogs {
    padding: 60px 0px;
  }
  .blog-banner {
    margin-bottom: 10px;
  }

  .single-blog {
    padding: 15px 15px;
  }
  .first-footer-box {
    margin-top: 30px;
  }
  .brand-logos-box img {
    width: 150px;
  }
  .banner-contant {
   height: 100%;
    justify-content: center;
    
}
.select-location {
    display: flex;
    align-items: end;
    flex-wrap: wrap;
}
.rated-business-box {
    text-align: center;
    margin-bottom: 0;
}
.sign-detail-input {
    margin-bottom: 15px;
}
.sign-up-details-box .mt-5.row {
    margin-top: 30px !important;
}
.edit_btns {
    display: flex;
    width: 70px;
}
.buisness_form_fields p {
  
    width: 110px;
}
.buisness_form_fields input {
  
    width: 45%;
}
.buisness_form_fields .form-select {
    width: 80%;
}
.buisness_form_fields {
   
    margin-bottom: 10px;
}
.main-category .category-select {
   
    margin-bottom: 5px;
   margin-top: 5px;
}

.Salon-Signup-btn .back-sign {
    padding: 6px 51px;
    margin: 0 !important;
}
.Salon-Signup-btn {
    text-align: center;
    margin-top: 40px;
    display: flex;
    gap: 10px;
    justify-content: center;
}
.select-city-input .input-group div {
  width: 100%;
}
.select-city-input input {
    width: 100%;
}
section.Map-section {
    display: none;
}
.featured-salon .item img {
    height: 339px;
    
}
.left-side-blog img {
    width: 100%;
    height: 320px;
    object-fit: cover;
}
.Salon-Detail-banner-btn a {
   width: 100%;
    margin: 0 ;
    
}
.Salon-Detail-banner-btn {
  
    gap: 10px;
}
.slick-slide img {
    width: 100%;
    height: 350px;
    object-fit: cover;
}
}

@media (max-width: 480px) {
  .Logo-top img {
    width: 150px;
    height: auto;
  }
  .Logo-top {
    margin-left: 0;
  }
  .navbar-top {
    padding: 18px 10px 20px 10px !important;
  }

  .Salon-Signup-btn a {
    padding: 12px 25px;
  }
  .choose-plan-title {
    font-size: 20px !important;
  }
  .blog-top-slide a {
    width: 43% !important;
    padding: 10px 15px;
  }
  .Terms-Use-box {
    padding: 30px 30px !important;
  }
  .email-box {
    width: 100%;
  }
  .button-inner span {
    width: 100px;

    font-size: 15px;
  }
  .button-inner {
    width: 100px;
  }
  .select-city-main {
    width: 300px !important;
}
.Salon-Signup-btn {
    text-align: center;
    margin-top: 40px !important;
    display: flex;
    gap: 10px;
    justify-content: center;
    flex-direction: column;
    width: 200px;
    margin: auto;
}
}


@media (max-width: 430px) {
.buisness_form_fields .form-select {
    width: 100%;
}
.edit_btns {
   
    margin-top: 8px;
}
}

@media(max-width:390px){
  .buisness_form_fields p {
    width: 80px;
}
.edit_btns {
    display: flex;
    width: 40px;
}
}