/* Futura Md BT font here  */

body {
  font-family: "Futura Md BT";
}

/* Futura Md BT font here  */

/* RecifeDisplay  font */

.RecifeDisplay {
  font-family: "RecifeDisplay";
}

.Futura-Lt-BT {
  font-family: "Futura Lt BT";
}

.FuturaBT {
  font-family: "Futura Hv BT";
}

/* RecifeDisplay  font */

/* header section start */
.common-heading {
  font-size: 40px !important;
}

.active .navbar-bottom {
  border-top: 1px solid rgba(7, 21, 35, 0.3);
  border-bottom: 1px solid rgba(7, 21, 35, 0.3);
}

.banner-img {
  background-image: url(../images/Homepage.jpeg);
  height: calc(100vh - 140px);
  background-position: top;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  background-size: cover;
  position: relative;
}

.navbar-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: 0.5s ease;
  padding: 30px 60px;
}

.Logo-top img {
  height: 40px;
}

.login-top a {
  margin: 0;
  color: #fff;
  font-size: 18px;
  font-weight: normal;
  text-decoration: none;
}

.social-icons-head svg {
  margin-right: 15px;
}

.social-icons-head {
  display: flex;
}

.footer-social {
  display: flex;
}

.navbar-bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgb(255 255 255 / 30%);
  padding: 20px 0px;
  border-right: 0px;
  border-left: none;
}

.navbar-bottom ul {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

.navbar-bottom ul li a {
  color: #fff;
  margin: 0px 30px;
  font-weight: 100;
  text-decoration: none;
  font-size: 14px;
}

.find-salon {
  display: flex;
  align-items: self-start;
  margin-bottom: 15px;
  margin-top: 30px;
}

.find-salon-text h1 {
  font-weight: bold;
  color: #fff;
  font-size: 40px;
}

.find-salon-text p {
  color: rgba(255, 255, 255, 0.8);
  font-size: 19px;
}

.find-salon img {
  margin-top: 8px;
  margin-right: 20px;
}

.select-city {
  display: flex;
  align-items: end;
}

.services-types label {
  font-size: 16px;
  color: #fff;
  margin-bottom: 5px;
}

.select-city-input label {
  font-size: 16px;
  color: #fff;
  margin-bottom: 5px;
}

.services-types {
  margin-right: 28px;
  width: 260px !important;
}

.select-city-input {
  margin-right: 28px;
  width: 260px !important;
}

.sreach-city img {
  background-color: #b9b5ab;
  padding: 10px;
  cursor: pointer;
}

.sreach-city {
  width: 10%;
}

.services-types select {
  padding: 20px 15px;
  border-radius: 0px;
}

.services-types select {
  padding: 11px 25px;
  border-radius: 0px;
  color: rgba(7, 21, 35, 0.5);
}

.select-city-input input {
  padding: 12px 25px;
  border-radius: 0;
  color: rgba(7, 21, 35, 0.5);
  border-right: 0;
  border: 0 !important;
}

.select-city-input input::placeholder {
  color: rgba(7, 21, 35, 0.5);
}

.form-control:focus {
  color: none !important;
  background-color: none !important;
  border-color: #b9b5ab !important;
  outline: 0;
  box-shadow: none !important;
}

.form-select:focus {
  border-color: none !important;
  outline: 0;
  box-shadow: none !important;
}

.select-city-input input:focus {
  outline: none;
}

.banner-text-bottom p {
  color: rgba(255, 255, 255, 0.5);
}

.banner-text-bottom p span {
  color: #fff;
}

.banner-text-bottom {
  margin-top: 10px;
}

.banner-contant {
  height: 80vh;
  display: flex;
  align-items: center;
  margin-top: 40px;
}

.Logo-top {
  margin-left: -40px;
}

.services-types .form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e");
  display: block;
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  appearance: none;
  background-color: var(--bs-body-bg);
  background-image: var(--bs-form-select-bg-img),
    var(--bs-form-select-bg-icon, none);
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: var(--bs-border-width) solid var(--bs-border-color);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  padding-right: 60px;
}

.select-city-input .input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  border-color: #ffffff !important;
  color: var(--bs-body-color);
  text-align: center;
  white-space: nowrap;
  background-color: #ffffff;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: 0 !important;
  border-left: 0;
}

.simple-header.active {
  background: #fff;
  left: 0;
  right: 0;

  transition: 0.5s ease;
  position: fixed;
  z-index: 1111;
}

.simple-header.active .navbar-top {
  display: none;
}

.simple-header.black-active {
  background: #fff;
  left: 0;
  right: 0;
  transition: 0.5s ease;
  top: 0;
  position: absolute;
  z-index: 111;
}

.simple-header.black-active .navbar-bottom ul li a {
  color: #000;
}

.black-active .black-logo {
  display: block !important;
}

.black-active .white-logo {
  display: none;
}

.simple-header.black-active svg path {
  fill: #000;
}

.simple-header.black-active .login-top a {
  color: #000;
}

.simple-header.active.scroll {
  transition: none;
}

.simple-header.black-active .navbar-bottom {
  border-top: 1px solid rgba(7, 21, 35, 0.3);
  border-bottom: 1px solid rgba(7, 21, 35, 0.3);
}

.simple-header.active .navbar-bottom ul li a {
  color: #000;
}

.simple-header {
  transition: 0.5s ease;
  position: absolute;
  top: 0;
  z-index: 111;
}

.simple-header .black-logo {
  display: none;
}

.simple-header.active .white-logo {
  display: none;
}

.simple-header.active .black-logo {
  display: block;
  z-index: 9999;
  position: relative;
}

.simple-header.active svg path {
  fill: #000;
}

.simple-header.active .login-top a {
  color: #000;
}

.simple-header.active .navbar-top {
  padding: 30px 60px;
}

.toggle-icon {
  display: none;
}

.select-city-input .input-group {
  position: static !important;
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
  width: 100%;
}

.input-group>.form-control,
.input-group>.form-floating,
.input-group>.form-select {
  position: static !important;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}

/* header section start */

/* Browse our most popular treatments  start */

.popular-treatments {
  background: #f0efec;
  padding: 100px 0;
}

.popular-treatments h1 {
  color: #071523;
  font-family: RecifeDisplay;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.treatment-head-txt h2 {
  color: #071523;
  font-size: 40px;
  font-weight: 700;
}

.treatment-head-txt {
  text-align: center;
}

.treatment-head-txt p {
  color: rgba(7, 21, 35, 0.8);
  font-size: 20px;
  font-weight: 400;
}

.treatment-items .items-box {
  /* background: #fff; */
  /* border: 1px solid #e8e8e8; */
  /* padding: 20px; */
  /* margin: 0px 20px; */
  /* border-radius: 10px; */
  text-align: center;
  /* box-shadow: 0px 10px 19px 0px rgba(0, 0, 0, 0.05); */
}

.treatment-items .items-box img {
  width: 160px !important;
  height: 175px;
  border-radius: 10px;
}

.treat-box-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 140px;
    margin: 15px;
}
.slick-slider {
    padding-top: 50px;
}
.items-box p {
  color: #071523;
  font-size: 20px;
  font-weight: 400;
  margin-top: 30px;
  margin-bottom: 0;
}

.side-treatment .owl-carousel .owl-stage-outer {
  padding: 40px 0px !important;
}

/* Browse our most popular treatments  end */

/* Featured Salon start */

.featured-salon-h h2 {
  color: #071523;
  font-size: 40px;
  font-weight: 700;
}

.featured-salon {
  padding: 100px 0;
  position: relative;
}

.featured-salon-text h3 {
  color: #071523;
  font-size: 28px;
  font-weight: 800;
}
.loader {
  border: 14px solid #f3f3f3; /* Light grey */
  border-top: 14px solid #000; /* Blue */
  border-radius: 50%;
  width: 100px;
  height: 100px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.featured-salon-text p {
  color: #071523;
  font-size: 18px;
  font-weight: 400;
  text-wrap: wrap;
}
.featured-salon .item img {
    height: 500px;
    object-fit: cover;
}
.view-profile-feature a {
  color: #000;
  font-size: 20px;
  font-weight: 400;
  position: relative;
  z-index: 11;
}

.featured-salon-text {
  width: calc(100% - 20%);
  margin: auto;
}

.featured-comment {
  margin: 30px 0px;
}

.featured-salon-h {
  margin-bottom: 50px;
}

.feature-star {
  height: 500px;
  position: absolute;
  right: 0;
  top: 20%;
  display: none!important;
}

/* Featured Salon end */

/* Why use a salon rated business start  */

.becoming-salon-title p {
  color: #fff;
  font-size: 25px;
  font-weight: 400;
}

.becoming-salon ul {
  padding: 0;
  list-style: none;
}

.becoming-salon ul li {
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 12px;
}

.becoming-salon ul li span {
  margin-right: 10px;
}

.rated-business {
  background-color: #b9b5ab;
  padding: 100px 0;
}

.rated-business-txt h2 {
  color: #071523;
  font-size: 50px;
  font-weight: 700;
}

.rated-business-txt {
  text-align: center;
  margin-bottom: 60px;
}

.rated-business-txt p {
  color: rgba(7, 21, 35, 0.8);
  font-size: 20px;
  font-weight: 400;
  width: 60%;
  margin: auto;
}

.rated-business-box {
  text-align: center;
}

.rated-business-box img {
  width: 100%;
  height: 162px;
  object-fit: contain;
}

.rated-business-box p {
  color: #071523;
  font-size: 20px;
  font-weight: 400;
  margin-top: 20px;
  margin-bottom: 0;
}

/* Why use a salon rated business end  */

/* What our clients say start*/

.clients-say {
  background-image: url("../images/clients-say-banner-img.webp");
  height: 700px;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  position: relative;
}

.clients-say::before {
  content: "";
  position: absolute !important;
  left: 0;
  right: 0;
  background: #000;
  width: 100%;
  height: 100%;
  opacity: 0.2;
  bottom: 0;
  top: 0;
}

.clients-say-right p {
  color: #fff;
  font-size: 20px;
  font-weight: 400;
}

.clients-say-right h2 {
  color: #fff;
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 30px;
}

.clients-say-text p {
  color: #fff;
  font-size: 18px;
  /* font-weight: 100; */
}

.clients-say-profile .user-img {
  width: 66px !important;
  height: 66px;
}

.clients-say-profile {
  display: flex;
  position: relative;
  margin: 30px 0px;
}

.clients-p-name h3 {
  color: #fff;
  font-size: 20px;
  font-weight: 400;
}

.clients-p-name p {
  font-size: 16px !important;
}

/* .clients-p-name {
  margin-left: 20px;
} */

.profile-img-bt {
  bottom: -13px;
  width: 27px !important;
  height: 27px !important;
  position: absolute;
  left: 39px;
}

.right-set {
  width: calc(100% - 20%);
  margin-left: unset;
}

/* What our clients say end */

/* Read Our Industry Blogs start */

.blog-recent-banner {
  background-image: url(../images/blog-banner-img.webp);
  height: 400px;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 50px;
}

.blog-slide-text {
  display: flex;
  align-items: center;
  height: 100%;
  padding-bottom: 70px;
}

.industry-blogs {
  background-color: #fff;
  padding: 100px 0px;
}

.industry-blogs-txt h2 {
  color: #071523;
  font-size: 50px;
  font-weight: 700;
}

.industry-blogs-txt {
  text-align: center;
  margin-bottom: 60px;
}

.industry-blogs-txt p {
  color: rgba(7, 21, 35, 0.8);
  font-size: 20px;
  font-weight: 400;
  width: 73%;
  margin: auto;
}

.left-side-blog img {
    width: 100%;
    height: 409px;
    object-fit: cover;
}

.left-side-blog p {
  margin-top: 30px;
  margin-bottom: 10px;
  color: rgba(7, 21, 35, 0.8);
  font-size: 18px;
  font-weight: 400;
}

.left-side-blog h2 {
  color: #071523;
  font-size: 30px;
  font-weight: 700;
}

.right-side-blog-list {
  display: flex;
  cursor: pointer;
}

.right-side-blog-list img {
    width: 134px;
    object-fit: cover;
    height: 134px;
}
.right-side-blog-text {
  margin-left: 20px;
}

.right-side-blog-text p {
  color: rgba(7, 21, 35, 0.8);
  font-size: 16px;
  font-weight: 400;
  margin-top: 15px;
}

.right-side-blog-text h3 {
  color: #071523;
  font-size: 22px;
  font-weight: 700;
}

.right-side-blog {
  margin-left: 20px;
}

.right-side-blog-list.border-top-bot {
  border-bottom: 1px solid rgba(185, 181, 171, 0.3);
  border-top: 1px solid rgba(185, 181, 171, 0.3);
  padding: 25px 0px;
}

/* Read Our Industry Blogs end */

/* Footer section start  */
.footer-logo p {
  margin: 0;
  color: #000;
  font-family: "Futura Lt BT";
  font-size: 14px;
  font-weight: 400;
}

.association-sec p {
  color: #000;
  font-family: "Futura Lt BT";
  font-size: 21px;
  font-weight: 400;
}

.footer-section {
  background: #e0ded9;
  padding: 60px 0;
}

.left-side-text {
  padding-bottom: 20px;
  border-bottom: 1px solid #071523;
}

.first-footer-box ul {
  list-style: none;
  padding: 0;
}

.first-footer-box ul li a {
  text-decoration: none;
  color: #000;
  font-size: 16px;
  font-weight: 400;
}

.first-footer-box ul li {
  margin-bottom: 10px;
}

.seco-footer-box ul {
  list-style: none;
  padding: 0;
}

.seco-footer-box ul li a {
  text-decoration: none;
  color: #000;
  font-size: 16px;
  font-weight: 400;
}

.seco-footer-box ul li {
  margin-bottom: 10px;
}

.seco-footer-box {
  margin-left: 70px;
}

.right-side-mail-send h2 {
  color: #071523;
  font-size: 25px !important;
  font-weight: 700;
}

.right-side-mail-send {
  width: 80%;
  margin-left: auto;
}

.footer-social img {
  margin-right: 25px;
  width: 25px;
}

.mail-send-footer input {
    display: flex;
    padding: 5px 55px 5px 15px;
    align-items: center;
    border-radius: 0;
    height: 45px;
    border: none !important;
}

.mail-send-footer .input-group-text {
  display: flex;
  align-items: center;
  padding: 0 !important;
}

.mail-send-footer .input-group-text button {
  border: none;
  background: #071523;
  height: 100%;
  color: #fff;
  width: 43px;
  font-size: 18px;
  z-index: 11;
}

.footer-logo img {
  width: 114px;
  height: 18px;
  margin-bottom: 20px;
}

.footer-logo {
  padding-top: 35px;
}

.certified-right img {
  width: 129px;
  height: 128px;
}

/* Footer section End  */

.side-treatment .owl-nav {
  display: block !important;
}

.side-treatment .owl-nav button span {
  font-size: 0;
}

.side-treatment .owl-nav button span {
  font-size: 0;
}

.side-treatment .owl-nav .owl-prev span::before {
  content: "";
  background-image: url("../images/left-slide-icon.svg");
  position: absolute;
  width: 40px;
  height: 40px;
  left: 0;
  right: 0;
  background-size: contain;
  top: 110px;
  left: -50px;
  background-repeat: no-repeat;
  bottom: 0;
  border-radius: 140px;
  box-shadow: 0px 5px 14px 0px rgba(0, 0, 0, 0.1);
}

.side-treatment .owl-nav .owl-next span::before {
  content: "";
  background-image: url("../images/right-slide-icon.svg");
  position: absolute;
  width: 40px;
  height: 40px;
  background-size: contain;

  top: 110px;
  right: -50px;
  background-repeat: no-repeat;
  bottom: 0;
  border-radius: 140px;
  box-shadow: 0px 5px 14px 0px rgba(0, 0, 0, 0.1);
}

.simple-header.active .toggle-icon svg path {
  stroke: #000;
}

.clients-say .owl-nav {
  display: block !important;
}

.clients-say .owl-nav button span {
  font-size: 0;
}

.clients-say .owl-nav button span {
  font-size: 0;
}

.clients-say .owl-nav .owl-prev span::before {
  content: "";
  background-image: url("../images/left-slide-icon.svg");
  position: absolute;
  width: 40px;
  height: 40px;
  left: 0;
  right: 0;
  background-size: contain;
  bottom: -90px;

  background-repeat: no-repeat;
}

.clients-say .owl-nav .owl-next span::before {
  content: "";
  background-image: url("../images/right-slide-icon.svg");
  position: absolute;
  width: 40px;
  height: 40px;
  background-size: contain;

  bottom: -90px;
  left: 80px;
  background-repeat: no-repeat;
}

.featured-salon .owl-nav {
  display: block !important;
}

.featured-salon .owl-nav button span {
  font-size: 0;
}

.featured-salon .owl-nav button span {
  font-size: 0;
}

.featured-salon .slick-prev:before {
  content: "";
  background-image: url("../images/chevarrow.svg");
 position: absolute;
     width: 50px;
    height: 22px;
    right: 0;
    background-size: contain;
   
    background-repeat: no-repeat;
}

.featured-salon .slick-next:before {
  content: "";
  background-image: url("../images/chevarrowt.svg");
  position: absolute;
  width: 50px;
  height: 50px;
  background-size: contain;
 
  background-repeat: no-repeat;
}
.featured-salon .slick-prev {
  top: 100%;
  left: 70%;
}

.featured-salon .slick-next {
top: 100%;
right: 15%;
}

.featured-salon .owl-next {
  float: right;
  font-size: 20px;
  text-transform: uppercase;
  padding: 20px;
}

.featured-salon .owl-dots {
  counter-reset: slides-num;
  position: absolute;
  bottom: -23px;
  right: 430px;
}

.featured-salon .owl-dots:after {
  content: counter(slides-num);
  /* get total number of items - more info on http://www.sitepoint.com/a-little-known-way-to-replace-some-scripts-with-css-counters/ */
  display: inline-block;
  font-size: 18px;
  color: rgba(7, 21, 35, 0.5);
  vertical-align: middle;
  padding-left: 10px;
}

.featured-salon .owl-dot {
  display: inline-block;
  counter-increment: slides-num;
  /* Increment counter */
  margin-right: 5px;
}

.featured-salon .owl-dot span {
  display: none;
}

.featured-salon .owl-dot.active:before {
  content: counter(slides-num) " /";
  /* Use the same counter to get current item. */
  display: inline-block;
  vertical-align: middle;
  font-size: 18px;
  font-weight: 700;
  position: absolute;
  left: -6px;
  top: 0;
  letter-spacing: -1px;
}

/* Login Page start  */

.login-main-box {
  border: 1px solid #b9b5ab;
  background: #f0efec;
  padding: 50px 70px;
}

.login-main-box h2 {
  color: #071523;
  text-align: center;
  font-size: 50px;
  font-weight: 700;
}

.login-main-box p {
  color: rgba(7, 21, 35, 0.8);
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 40px;
}

.login-input input {
  border-radius: 0;
  border: 1px solid #b9b5ab;
  background: #fff;
  height: 56px;
}

.login-input input::placeholder {
  color: rgba(7, 21, 35, 0.5);
}

.login-input {
  margin-bottom: 25px;
}

.login-input label {
  margin-bottom: 5px;
}

.forgot-page-link {
  text-align: end;
}

.login-btn {
  text-align: center;
  margin-top: 40px;
}

.login-btn button {
  background: #071523;
  padding: 15px 150px;
  border: none;
  color: #fff;
  font-size: 16px;
}

.Login-main-section {
  margin-top: 220px;
  margin-bottom: 60px;
}

/* Login Page End  */

/* Sign Up Main Text start */

.choose-plan-title {
  font-size: 28px !important;
  margin-bottom: 50px !important;
}

.choose-plan-box .Salon-Signup-btn {
  margin-top: 80px;
}

.choose-plan-box {
  padding: 80px 20px !important;
}

.Salon-Signup-btn .back-sign {
  border: 1px solid;
  background: #fff;
  color: #071523;
  padding: 10px 60px;
}

.Sign-main-section {
  margin-top: 130px;
  padding: 100px 0px;
  background: #f0efec;
}

.Sign-main-text {
  text-align: center;
}

.Sign-main-text h2 {
  color: #071523;
  font-size: 50px;
  font-weight: 700;
}

.Sign-main-text p {
  color: rgba(7, 21, 35, 0.8);
  font-size: 20px;
  font-weight: 400;
  width: 67%;
  margin: auto;
}

.sign-up-details-box {
  border: 1px solid #b9b5ab;
  background: #fff;
}

.sign-up-details-box {
  padding: 80px 100px;
}

.sign-up-details-box h2 {
  color: #000;
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 30px;
}

.sign-detail-input input {
  border: 1px solid #b9b5ab;
  background: #fff;
  border-radius: 0;
  height: 50px;
}

.sign-detail-input label {
  color: #071523;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 5px;
}

.sign-detail-input {
  margin-bottom: 30px;
}

.sign-bottom-border {
  border-bottom: 1px solid rgba(7, 21, 35, 0.1);
  padding-bottom: 20px;
}

.Sign-main-text {
  text-align: center;
  margin-bottom: 50px;
}

.sign-upld-input label {
  color: #071523;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 5px;
}
.sign-detail-input a {
  margin: 10px 0;
  display: block;
}
.sign-upld-input a {
  margin: 10px 0;
  display: block;
}
.img-upld-box {
    text-align: center;
    position: relative;
    border: 2px dashed #b9b5ab;
    padding: 30px;
    height: 175px;
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.img-upld-box input {
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
}

.img-upld-box p {
  color: #071523;
  font-size: 16px;
  font-weight: 400;
  margin-top: 15px;
}

.sign-profile img {
  width: 180px;
  height: 180px;
  object-fit: cover;
}

.sign-profile {
  display: flex;
  align-items: center;
  position: relative;
}

.sign-upload-btn {
  position: relative;
  cursor: pointer;
  margin-left: 40px;
}

.sign-upload-btn input {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.sign-upload-btn button {
  background: #071523;
  border: none;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  padding: 10px 20px;
  cursor: pointer;
}

.category-select {
  display: flex;
  align-items: end;
  margin-bottom: 30px;
  margin-top: 30px;
}

.main-category .category-select {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  min-width: 274px;
}

.category-select input {
  width: 25px;
  height: 25px;
  cursor: pointer;
  border: 2px solid #b9b5ab;
  border-radius: 0 !important;
}

.category-select label {
  color: #071523;
  font-size: 16px;
  font-weight: 400;
  margin-left: 20px;
}

.profile-descri {
  position: relative;
}

.profile-descri label {
  color: #071523;
  font-size: 16px;
  font-weight: 400;
}

.profile-descri textarea {
  border: 1px solid #b9b5ab;
  background: #fff;
  border-radius: 0 !important;
  padding: 12px 12px 50px 12px;
  resize: none;
}

.profile-descri textarea:focus {
  border: 1px solid #b9b5ab !important;
  background: #fff;
  border-radius: 0 !important;
  padding: 12px 12px 50px 12px;
}

.profile-descri textarea::placeholder {
  color: rgba(7, 21, 35, 0.5);
  font-size: 16px;
  font-weight: 400;
}

.profile-descri p {
  color: rgba(7, 21, 35, 0.5);
  font-size: 16px;
  font-weight: 400;
  position: absolute;
  right: 20px;
  bottom: 0;
}

/* Sign Up Main Text End */

/* subscription section start  */

.subscription-section {
  padding: 100px 0px;
}

.bronze-box {
  border-radius: 10px;
  border: 1px solid #d9d9d9;
  background: #fff;
  padding: 50px 15px;
}

.bronze-box:hover {
  box-sizing: content-box;
  box-shadow: 0px 24px 20px 0px rgba(240, 239, 236, 0.7);
  transition: 0.5s ease;
}

.what-included {
  margin-top: 25px;
}

.what-included {
  margin-top: 25px;
  background: #f0efec;
}

.offer-tittle h2 {
  color: #071523;
  font-size: 30px;
  font-weight: 700;
}

.offer-tittle p {
  color: #071523;
  font-size: 16px;
  font-weight: 400;
}

.price-rate {
  display: flex;
  align-items: center;
  justify-content: center;
}

.price-rate span {
  color: #071523;
  font-size: 20px;
  font-weight: 400;
}

.offer-tittle .yearli-bill {
  color: #071523;
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
}

.what-included {
  margin-top: 25px;
  background: #f0efec;
  padding: 30px 15px;
  height: 550px;
}

.what-included h2 {
  color: #071523;
  font-size: 22px;
  text-transform: uppercase;
}

.price-rate h3 {
  color: #071523;
  font-size: 50px;
  margin: 0;
}

.what-included p {
  color: #071523;
  font-size: 16px;
  font-weight: 400;

  line-height: 1.3;
}

.what-included ul {
  list-style: none;
  padding: 20px 0 0 0;
  border-top: 1px solid rgba(7, 21, 35, 0.1);
}

.what-included ul li span {
  margin-right: 10px;
}

.what-included ul li {
  margin-bottom: 15px;
}

.offer-btn {
  margin-top: 50px;
  text-align: center;
}

.offer-btn button {
  border: 1px solid #b9b5ab;
  background: #fff;
  color: #071523;
  font-size: 16px;
  padding: 10px;
  width: 100%;
}

.Popular-section {
  position: relative;
}

.Popular-tag {
  position: absolute;
  top: -20px;
  left: 20px;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  text-transform: uppercase;
  border-radius: 6px;
  background: #071523;
  padding: 8px 20px;
}

.Salon-Signup-btn {
  text-align: center;
  margin-top: 40px;
}

.Salon-Signup-btn button {
  background: #071523;
  border:1px solid #000;
  border: none;
  padding: 10px 40px;
  color: #fff;
  font-size: 18px;
}

.Salon-Signup-btn a {
  background: #071523;
  border: none;
  padding: 12px 50px;
  color: #fff;
  font-size: 18px;
  text-decoration: none;
  display: inline-block;
}

/* subscription section End  */

/* Search Salons start  */

.Search-input-section {
  margin-top: 160px;
  padding: 100px 0px;
  background: #f0efec;
}

.salon-map img {
  width: 100%;
}

.rated-salons-card img {
  width: 15%;
}

.rated-salons-card {
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 10px;
  cursor: pointer;
  background: #fff;
  box-shadow: 6px 6px 20px 0px rgba(0, 0, 0, 0.06);
  padding: 20px;
  justify-content: space-between;
  margin-bottom: 20px;
}

.rated-salons {
  padding: 100px 0px;
  background: #f0efec;
}

.rated-salon-tittle {
  text-align: center;
  margin: 50px 0px;
}

.rated-salon-tittle h2 {
  color: #071523;
  font-size: 50px;
  font-weight: 700;
}

.salons-card-contact {
  display: flex;
}

.salons-card-details {
  margin-left: 20px;
}

.salons-card-contact img {
  width: 80%;
}

.salons-card-contact {
  display: flex;
  align-items: center;
}

.salons-card-contact p {
  margin: 0;
  margin-top: 2px;
}

.salons-card-contact span {
  margin-right: 5px;
}

.salons-card-details h3 {
  color: #000;
  font-size: 25px;
}

.salons-card-details .Futura-Lt-BT {
  color: #000;
  font-size: 18px;
}
.cluster {
  display: flex;
  align-items: center;
  justify-content: center;
}
.cluster span {
  font-size: 12px;
  margin-top: 2px;
}
.salons-card-btn a {
  background: #b9b5ab;
  color: #fff;
  text-decoration: none;
  min-width: 190px;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 12px;
}

.salons-card-btn {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 111;
}

.rated-salons-scroll {
  height: 80vh;
  overflow-y: scroll;
  padding: 10px 30px;
}

/* width */
.rated-salons-scroll::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.rated-salons-scroll::-webkit-scrollbar-track {
  background: #d9d9d9;
  border-radius: 10px;
}

/* Handle */
.rated-salons-scroll::-webkit-scrollbar-thumb {
  background: #b9b5ab;
  border-radius: 10px;
}

/* Handle on hover */
.rated-salons-scroll::-webkit-scrollbar-thumb:hover {
  background: #b9b5ab;
  border-radius: 10px;
}

.salon-info {
  display: flex;
  align-items: center;
}

/* Search Salons End  */

/* Contact us section start  */

.contact-us-banner {
  height: 507px !important;
  background-image: url(../images/contact-us-banner.webp);
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: end;
  padding-bottom: 130px;
  position: relative;
}

.contact-title-main {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  position: relative;
}

.contact-title {
  text-align: center;
}

.contact-title h2 {
  color: #fff;
  font-weight: 700;
}

.contact-title p {
  color: #fff;
  font-size: 20px;
  font-weight: 400;
  width: 60%;
  margin: auto;
}

.contact-card {
  border: 1px solid #b9b5ab;
  background: #f0efec;
  padding: 100px 50px;
}

section.contact-box {
  padding: 60px 0px;
}

.contact-card-box {
  text-align: center;
}

.contact-card-box .icon-img img {
  background: #fff;
  padding: 30px;
  border-radius: 10px;
}

.contact-card-box h3 {
  color: #071523;
  font-size: 30px;
  font-weight: 700;
  margin-top: 20px;
}

.contact-card-box p {
  color: #000;
  text-align: center;
  font-size: 18px;
  font-weight: 400;
}

/* Contact us section End */

/* Livechat page start  */

.Livechat-section {
  margin-top: 220px;
  margin-bottom: 60px;
}

.livechat-box {
  border: 1px solid #b9b5ab;
  background: #f0efec;
  padding: 50px 70px;
  text-align: center;
}

.livechat-box h2 {
  color: #071523;
  font-weight: 700;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}

/* Livechat page end  */

/* Salon Detail Page start  */

.salon-detail-banner {
  background-image: url(../images/salon-detail-review.webp);
  position: relative;
}

.Salon-Detail-banner {
  text-align: center;
}

.Salon-Detail-banner img {
  width: 110px;
  height: 110px;
  object-fit: cover;
}

.Salon-Detail-banner h3 {
  color: #fff;
  font-size: 40px;
  font-weight: 400;
  margin: 20px 0px;
}

.Salon-Detail-banner p {
  color: #fff;
  font-size: 20px;
  font-weight: 400;
}

.Salon-Detail-banner-btn button {
  background: #b9b5ab;
  border: 0;
  margin: 0px 20px;
  width: 210px;
  height: 57px;
  color: #fff;
}

.Salon-Detail-banner-btn {
  margin-top: 50px;
  display: flex;
    justify-content: center;
    align-items: center;
}

.banner-img-salon {
  background-image: url(../images/salon-detail-banner.webp);
  height: 654px;
  background-position: top;
  background-repeat: no-repeat;
  display: flex;
  align-items: end;
  background-size: cover;
  padding-bottom: 40px;
}

/* Salon Detail Page End  */

/* Salon detail contact start */

.Salon-detail-contact {
  padding: 100px 0;
}

/* Salon detail contact end */

/* Brand Used start */

.brand-user {
  padding: 80px 0px;
  background: #f0efec;
}

.brand-head-txt h2 {
  color: #071523;
  font-size: 40px;
  font-weight: 700;
  text-align: center;
  margin-top: 40px;
}

.brand-user-logo .brand-logo-top {
  width: 140px;
  height: 140px;
  object-fit: contain;
  margin: 13px 10px;
}



.brand-user-logo {
  text-align: center;
}

/* Brand Used End */

/* follow social icon start */
.follow-social {
  padding: 80px 0px;
}

.follow-icon-contin {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: auto;
}

/* .follow-icon-box {
  border-radius: 10px;
  border: 1px solid #ededed;
  background: #fff;
  box-shadow: 0px 15px 24px 0px rgba(0, 0, 0, 0.07);
  padding: 25px;
} */

.follow-icon-box img {
  height: 115px;
  width: 115px;
}

/* follow social icon End */

/* Blog Main start  */

.side-blog-box .blog-text-card {
  margin-top: 0;
  margin-left: 15px !important;
}

.blog-side-title p {
  color: #071523;
  font-size: 24px;
  font-weight: 600;
}
.clients-p-name {
    margin-left: 10px;
}
.blog-side-part img {
  object-fit: cover;
}
.side-blog-box .blog-text {
  font-weight: 400;
  color: #071523;
}

.blog-text-card {
  margin-left: 20px;
  margin-top: -10px;
}

.black-logo.blog-card-img {
  height: 160px;
  object-fit: cover;
  border-radius: 10px;
}

.aur-blog-box {
  /* background-color: #fff;
    padding: 40px 80px; */
}

.blog-card-box {
  border: 1px solid #b9b5ab;
  background: #fff;
  padding: 30px 20px;
  display: flex;
  align-items: center;
}

.blog-card-box img {
  width: 30%;
}

.blog-card-box .Futura-Lt-BT {
  color: #000;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 5px;
}

.blog-card-box {
  margin-bottom: 40px;
}

.blog-text {
  color: #071523;
  font-size: 22px;
  font-weight: 400;
  margin-bottom: 20px;
  line-height: 28px;
}

.blog-card-box a {
  padding: 14px 20px;
  text-decoration: none;
  color: #b9b5ab;
  font-size: 18px;
  border: 1px solid #b9b5ab;
}

.blog-card-box a svg path {
  fill: #b9b5ab;
}

.blog-card-box a svg {
  margin-left: 8px;
}

img.blog-next {
  width: 4%;
  margin-left: 10px;
}

/* Blog Main End  */

/* Blog Details start  */

.blog-banner img {
    width: 100%;
    height: 415px;
    object-fit: cover;
}

.side-blog-box img {
  width: 30%;
  height: 90px;
  border-radius: 5px;
}

.side-blog-box p {
  font-size: 16px;
}

.side-blog-box {
  border: 1px solid #b9b5ab;
  padding: 15px;
}

.side-blog-box {
  border: 1px solid #b9b5ab;
  padding: 15px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  text-decoration: none;
  color: #071523;
}

.blog-detail-tittle h2 {
  color: #071523;
  font-size: 50px;
  font-weight: 700;
  margin-bottom: 20px;
}

.blog-detail-tittle p {
  color: #000;
  font-size: 18px;
  font-weight: 400;
}

.blog-para img {
  width: 100%;
  margin: 20px 0px;
  height: 350px;
  object-fit: cover;
}

.blog-para h4 {
  color: #071523;
  font-size: 26px;
  font-weight: 400;
}

.blog-para p {
  margin-bottom: 30px;
}

.blog-Previous {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  padding: 15px;
  text-decoration: none;
  width: 70%;
}

.blog-Previous img {
  width: 20%;
}

.blog-Previous-text img {
  width: 7px;
  height: 12px;
  margin-right: 5px;
}

.blog-Previous-text {
  margin-left: 20px;
}

.blog-Previous-text p {
  color: #a4a4a4;
  font-size: 16px;
  display: flex;
  align-items: center;
  font-weight: 400;
}

.blog-Previous-text .card-text {
  color: #071523;
  font-size: 16px;
  font-weight: 400;
}

.blog-Next {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  padding: 15px;
  text-decoration: none;
  width: 70%;
}

.blog-Next img {
  width: 20%;
}

.blog-Next-text img {
  width: 7px;
  height: 12px;
  margin-left: 5px;
}

.blog-Next-text {
  margin-right: 20px;
  text-align: end;
}

.blog-Next-text p {
  color: #a4a4a4;
  font-size: 16px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: end;
}

.blog-Next-text p span {
  display: flex;
  align-items: center;
}

.blog-Next-text .card-text {
  color: #071523;
  font-size: 16px;
  font-weight: 400;
}

/* Blog Details End  */

/* Help & Support start  */

.drop-us-line {
  text-align: center;
}

.drop-us-line h2 {
  color: #071523;
  font-size: 35px;
  font-weight: 400;
  margin-bottom: 15px;
}

.drop-us-line p {
  color: #071523;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 35px;
}

.drop-us-submit {
  text-align: center;
  margin-top: 40px;
}

.drop-us-submit button {
  background: #071523;
  border: 0;
  color: #fff;
  padding: 15px 70px;
  font-size: 18px;
}

.support-view-section {
  padding: 100px 0px;
}

.support-view-left img {
  width: 90%;
  border-radius: 10px;
  box-shadow: 6px 6px 100px 0px rgba(0, 0, 0, 0.1);
}

.support-view-right p {
  color: rgba(7, 21, 35, 0.8);
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 20px;
}

.support-view-right {
  text-align: center;
}

.faq-question .accordion-item {
  border: 0px !important;
  background: #fff !important;
  border-radius: 0 !important;
  margin-bottom: 20px;
}

.faq-question .accordion-header {
  margin: 0;
  border: 1px solid #b9b5ab !important;
}

.faq-question .accordion-body {
  color: #737373;
  font-size: 16px;
  line-height: 25px;
}

.faq-question .accordion-button:not(.collapsed) {
  color: rgba(7, 21, 35, 0.8) !important;
  background-color: #fff !important;
  box-shadow: none !important;
}

.faq-question .accordion-button:not(.collapsed) {
  background-color: #ffffff !important;
}

.faq-question .accordion-button:focus {
  z-index: 3;
  border-color: #fff;
  outline: 0;
  box-shadow: none !important;
}

.faq-box-section {
  padding: 100px 0px;
  background: #f0efec;
}

/* Help & Support End  */

/* Leave us a Review start */

.sign-profile.review-page-upld {
  display: flex;
  flex-direction: column;
}

.review-page-upld img {
  width: 180px;
  height: 180px;
  object-fit: cover;
  margin-bottom: 20px;
}

.review-page-upld .sign-upload-btn {
  position: relative;
  cursor: pointer;
  margin-left: 0 !important;
  width: 80% !important;
}

.review-page-upld .sign-upload-btn button {
  width: 100% !important;
}

/* Leave us a Review End */

/* Terms of Use start */

.Terms-Use-box {
  background: #fff;
  border: 1px solid #b9b5ab;
  padding: 60px 100px;
}

.Terms-Use-box p {
  color: #666;
  font-size: 18px;
}

.Terms-Use-box h4 {
  color: #071523;
  font-size: 22px;
  margin-top: 30px;
}

.Terms-Use-box p b {
  color: #071523;
  font-size: 18px;
  font-weight: 400;
}

/* Terms of Use End */

/* Careers start */

.Careers-box {
  background: #fff;
  border: 1px solid #b9b5ab;
  padding: 80px 100px;
  text-align: center;
}

.email-box {
  border: 1.5px solid #b9b5ab;
  padding: 10px;
  width: 30%;
  margin: auto;
}

/* Careers End */

/* Why become a Salon Rated Salon? start */

.why-salon-rated {
  width: 100%;
}

/* Why become a Salon Rated Salon? end */

.category-select .form-check-input:checked {
  background-color: #b9b5ab;
  border-color: #b9b5ab;
}

.category-select .form-check-input:focus {
  border-color: #b9b5ab;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(255 255 255 / 0%);
}

/* dashboard section start  */

.dashboard-banner {
  background-image: url(../images/dashboard-banner.webp);
  height: 670px !important;
  background-position: top;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  padding-bottom: 60px;
  position: relative;
  background-size: cover;
}

.dashboard-banner::before {
  content: "";
  position: absolute !important;
  left: 0;
  right: 0;
  background: #000;
  width: 100%;
  height: 100%;
  opacity: 0.2;
  bottom: 0;
  top: 0;
}

.dashboard-banner .contact-title {
  text-align: center;
  margin-top: 120px !important;
}

.dashboard-salon-view {
  background: #f0efec;
  padding: 100px 0;
}

.salon-logo-detials {
  text-align: center;
}

.salon-logo-detials img {
  height: 140px;
}

.salon-logo-detials h2 {
  color: #071523;
  text-align: center;
  font-size: 40px;
  font-weight: 400;
  margin: 40px 0px;
}

.salon-statics {
  text-align: center;
}

.salon-statics-icon img {
  background: #ffffff;
  padding: 30px;
  border: 1px solid #b9b5ab;
  border-radius: 15px;
  height: 120px;
}

.salon-statics h3 {
  color: #071523;
  font-size: 22px;
  font-weight: 700;
  margin: 15px 0px;
}

.salon-statics p {
  color: #000;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
}

.dash-clients-say {
  background-image: url("../images/dash-client-say.webp");
  height: 50%;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 200px 0px;
}

/* dashboard section End  */

/* Review Details section start  */

.review-rating h2 {
  color: #071523;
  font-size: 40px;
  font-weight: 400;
}

.review-rating p {
  color: #071523;
  font-size: 25px;
  font-weight: 400;
}

.review-rating {
  text-align: center;
}

.Review-Details-box {
  background: #fff;
  border: 1px solid #b9b5ab;
  padding: 80px 100px;
}

.services-took h2 {
  color: #000;
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 20px;
}

.services-took ul {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}

.services-box p {
  background: #b9b5ab;
  color: #fff;
  padding: 10px 20px;
  margin-right: 20px;
}

.before-after-img img {
  width: 100%;
}

.before-after-img {
  text-align: center;
}

.before-after-img p {
  color: #000;
  font-size: 24px;
  font-weight: 400;
  margin-top: 12px;
}

/* Review Details section End  */

/* Your plan section start  */

.plan-detail-box-two ul {
  list-style: none;
  padding: 20px 0 0;
}

.plan-detail-box-two ul li {
  margin-bottom: 15px;
}

.plan-detail-box-two ul li span {
  margin-right: 10px;
}

.plan-detail-box-two p {
  color: #071523;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 0;
}

.plan-detail-box-two h3 {
  color: #071523;
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
}

.plan-detail-box-three .yearli-bill {
  color: #071523;
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
}

.your-plan-card {
  border: 1px solid #b9b5ab;
  background: #fff;
  padding: 30px;
}

.plan-detail-box-one h3 {
  color: #071523;
  font-size: 24px;
  font-weight: 700;
}

.plan-detail-box-one p {
  color: #071523;
  font-size: 20px;
  font-weight: 400;
}

.Your-Plan-box {
  background: #fff;
  border: 1px solid #b9b5ab;
  padding: 40px 40px;
  text-align: center;
}

.plan-detail-box-two {
  border-right: 1px solid rgba(7, 21, 35, 0.1);
  border-left: 1px solid rgba(7, 21, 35, 0.1);
  padding: 0px 30px;
}

.plan-change {
  margin-top: 30px;
}

.plan-change button {
  background: #071523;
  border: none;
  color: #fff;
  font-size: 18px;
  padding: 15px 50px;
}

.plan-change p {
  color: #071523;
  font-size: 16px;
  font-weight: 400;
  margin-top: 20px;
}

/* Your plan section end  */

/* Manage Your Account  Start */

.active-tab a {
  color: #071523 !important;
}

.manage-account {
  background: #fff;
  border: 1px solid #b9b5ab;
}

.account-tab ul {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  border-bottom: 1px solid #b9b5ab;
}

.account-tab ul li {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  position: relative;
}

.account-tab a {
  margin: 0;
  color: #c8c8c8;
  font-size: 16px;
  font-weight: 400;
  text-decoration: none;
}

.account-tab span {
  margin-right: 8px;
}

.details-data-box {
  padding: 40px 80px;
}

.offered-treatments {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
}

.offered-treatments p {
  color: #000;
  font-size: 18px;
  font-weight: 400;
  margin: 0;
}

.offered-treatments .treatment-box {
  border: 1.5px solid #b9b5ab;
  padding: 8px 12px;
  color: #b9b5ab;
  font-size: 17px;
  font-weight: 400;
  width: fit-content;
  margin: 0px 10px;
  text-align: center;
}

/* Manage Your Account  End */

.profile-bio-descr p {
  color: #071523;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  margin: 0;
}

.profile-bio-descr {
  border: 1px solid #071523;
  background: #fff;
  padding: 12px 30px;
}

/* Reviews section start  */

.review-card {
  display: flex;
  background: rgba(255, 255, 255, 0.95);
  box-shadow: 0px 10px 24px 0px rgba(0, 0, 0, 0.15);
  padding: 20px;
  border-radius: 10px;
  text-decoration: none;
  margin-bottom: 30px;
}

.before-img img {
  width: 90%;
  height: 230px;
  object-fit: cover;
  border-radius: 10px;
}

.after-img img {
  width: 90%;
  height: 230px;
  object-fit: cover;
  border-radius: 10px;
}

.review-card-imgs {
  display: flex;
}

.review-card-imgs {
  display: flex;
  width: 40%;
}

.review-card-text {
  width: 100%;
  text-align: left;
  padding-left: 20px;
}

.review-card-name p {
  color: #071523;
  font-size: 25px;
  font-weight: 400;
  margin: 0;
}

.review-card-text p {
  margin: 0;
  color: #071523;
  display: inline-block;
  width: 232px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.review-card-text .Futura-Lt-BT {
  font-size: 16px;
}

.review-star {
  margin: 10px 0px;
}

.review-star span {
  margin-right: 8px;
}

.review-card-imgs .before-img {
  position: relative;
}

.review-card-imgs .before-img span p {
  position: absolute;
  z-index: 9;
  left: 8px;
  top: 0;
  border-radius: 10px 0px;
  background: #b9b5ab;
  color: #fff;
  padding: 5px 10px;
  font-size: 14px;
  width: 50%;
}

.review-card-imgs .after-img {
  position: relative;
}

.review-card-imgs .after-img span p {
  position: absolute;
  z-index: 9;
  left: 10px;
  top: 0;
  border-radius: 10px 0px;
  background: #b9b5ab;
  color: #fff;
  padding: 5px 10px;
  font-size: 14px;
  width: 50%;
}

.review-card-imgs .after-img-bottom-icon {
  position: absolute;
  z-index: 9;
  right: -25px;
  bottom: -20px;
  color: #fff;
  padding: 5px 10px;
  font-size: 14px;
  width: 50%;
}

.review-box {
  background: #fff;
  border: 1px solid #b9b5ab;
  padding: 80px 100px;
  text-align: center;
}

.bronze-box.set-active {
  transform: scaleY(1.08);
  border-radius: 10px;
  padding-bottom: 55px;
  padding-top: 81px;
  border: 3px solid #b9b5ab;
  background: #fff;
  box-shadow: 0px 24px 20px 0px rgba(240, 239, 236, 0.7);
  transition: 0.5s ease;
}

/* Reviews section end  */

.bronze-box.set-active .what-included {
  height: 500px;
}

.bronze-box.set-active .offer-btn button {
  border: 1px solid #b9b5ab;
  background: #b9b5ab;
  color: #fff;
}

.Rate-Us .star-svg {
  margin-right: 15px;
  width: 30px;
}

.account-tab span {
  margin-right: 8px;
  margin-top: -4px;
}

.rating-star .star-svg {
  width: 40px !important;
  height: 40px !important;
  margin-right: 15px;
}

.account-tab ul .active-tab svg path {
  fill: #000;
}

.login-btn a {
  background: #071523;
  padding: 15px 150px;
  border: none;
  color: #fff;
  font-size: 16px;
  text-decoration: none;
}

.last-menu {
  position: absolute;
  right: 30px;
}

.salon-map iframe {
  width: 100%;
  height: 630px;
}

.simple-header.black-active .toggle-icon svg path {
  stroke: #000;
}

.navbar-bottom .login-btn {
  display: none;
}

.blog-top-slide a {
  background: #b9b5ab;
  padding: 14px 20px;
  text-decoration: none;
  color: #fff;
  font-size: 16px;
  display: flex;
  width: 25%;
  align-items: center;
}

.blog-top-slide .blog-next {
  width: 80% !important;
  margin-left: 10px;
}

.blog-top-slide p {
  color: #fff;
  font-size: 22px;
  font-weight: 400;
}

.blog-top-slide h2 {
  color: #fff;
  font-family: "Futura Md BT";
  font-size: 20px;
  font-weight: 400;
}

.Terms-Use-box img {
  width: 100%;
  margin-bottom: 30px;
}

.blog-banner {
  margin-bottom: 50px;
}

.add-review-text {
  position: absolute;
  width: 20%;
  right: 0;
  bottom: 40px;
}

.add-review-text a {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  text-decoration-line: underline;
}

.tab-dots ul {
  display: flex;
  justify-content: space-between;
  list-style: none;
  margin: 0;
  padding: 0;
  z-index: 1;
  position: relative;
}

.tab-dots ul li {
  width: 35px;
  height: 35px;
  border-radius: 45px;
  border: 1px solid #b9b5ab;
  background: #fff;
  position: relative;
}

.tab-dots ul li p {
  margin-top: 50px;
  width: 55px;
  color: #0715234d;

  font-family: "Futura Md BT";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.pagination-tab .progress-bar {
  background: #d9d9d9;
}

.pagination-tab .progress {
  height: 4px;
}

.pagination-tab .progress {
  height: 4px;
  position: absolute;
  bottom: 17px;
  width: 100%;
}

.pagination-tab {
  position: relative;
  margin-bottom: 80px !important;
  width: 70%;
  margin: auto;
}

.tab-dots ul .active-dot:before {
  padding: 0px;
  content: "";
  position: absolute;
  background: red;
  width: 100%;
  height: 40px;
}

.tab-dots ul .active-dot:before {
  padding: 0px;
  content: "";
  position: absolute;
  background: #b9b5ab;
  width: 27px;
  height: 27px;
  top: 3px;
  border-radius: 36px;
  bottom: 0;
  left: 3px;
  right: 0;
}

.tab-dots ul .active-dot p {
  color: rgba(7, 21, 35, 0.8);
}

.tab-dots ul .tick:after {
  content: "";
  position: absolute;
  background-image: url("../images/tickmark.svg");
  left: 0;
  right: 0;
  top: 0;
  background-repeat: no-repeat;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: 14px;
}

.button-inner span {
  width: 167px;
  border: 1px solid #b9b5ab;
  background: #fff;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(7, 21, 35, 0.5);
  font-family: "Futura Md BT";
  font-size: 16px;
  font-weight: 400;
}

.button-inner input {
  position: absolute;
  left: 0;
  right: 0;
  opacity: 0;
  height: 100%;
  cursor: pointer;
  width: 100%;
}

.button-inner {
  width: 167px;
  height: 40px;
  margin-right: 25px;
  position: relative;
}

.button-inner input:checked~span {
  border: 1px solid #b9b5ab;
  background: #b9b5ab;
  color: #fff;
}

.radio-buttons {
  flex-wrap: wrap;
  display: flex;
  margin-top: 10px;
}

.blog-slide-text .owl-dots {
  text-align: left;
  position: absolute;
  bottom: -80px;
  margin-left: 10px;
}

.blog-slide-text .owl-theme .owl-dots .owl-dot span {
  background: #000;
}

.blog-slide-text .owl-theme .owl-dots .owl-dot.active span {
  background: #fff;
}

.blog-recent-banner .row {
  justify-content: end;
}

.single-blog {
  background-color: #fff;
  padding: 35px 35px;
  border: 1px solid #b9b5ab;
}

.blog-Previous-text p span {
  display: flex;
  align-items: center;
}

.bronze-box.set-active .offer-tittle h2 {
  margin-bottom: 23px;
}

.account-tab ul .active-tab::before {
  content: "";
  position: absolute;
  background: #000;
  width: 100%;
  height: 3px;
  bottom: -16px;
}

.modal-city-srch {
  display: flex;
  align-items: end;
  padding: var(--bs-modal-padding);
}

.modal-set-srch {
  padding: 20px 40px;
}

.modal-set-srch h3 {
  color: #071523;
  font-size: 25px;
  font-weight: 700;
  margin: 0;
}

.modal-cls-icon {
  text-align: end;
}

.cursor-pointer {
  cursor: pointer;
}

.modal-city-srch .select-city-input {
  width: 100%;
}

.popup-center-dspy .modal-dialog {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  margin-right: auto;
  margin-left: auto;
}

.select-location {
  display: flex;
  align-items: end;
}

.select-location-main {
  display: none;
}

.select-city-main.hide {
  display: none;
}

.select-location-main.show {
  display: block;
}

.banner-text-bottom p span {
  color: #fff;
  cursor: pointer;
}

/* .featured-salon .owl-carousel .owl-stage {
  right: 269px;
} */

.why-salon-listing {
  background-image: url("../images/salon-rated-img.webp");
  background-repeat: no-repeat;
  background-size: cover;
  height: 665px;
  display: flex;
  position: relative;
  padding: 0 100px;
  z-index: 1;
  align-items: center;
}
.becoming-salon p {
    color: #fff;
    margin-left: 25px;
}
.why-salon-listing::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  z-index: -1;
  height: 100%;
  background: #000;
  opacity: 0.5;
}

.we-recommend-box {
  background: #fff;
  border: 1px solid #b9b5ab;
  padding: 60px 100px;
}

.we-recommend-box h2 {
  color: #071523;
  text-align: center;
  font-size: 30px;
  font-weight: 700;
}

.brand-logos-box {
  border: 1px solid #b9b5ab;
  background: #fff;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;
}

.why-salon-box {
  background: #fff;
  border: 1px solid #b9b5ab;
  padding: 60px 60px;
}

.becoming-salon-title h3 {
  color: #fff;
  font-size: 30px !important;
  margin-bottom: 20px;
}

.pagination-box ul {
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: baseline;
}

.pagination-box ul li {
  margin: 0px 10px;
}

.pagination-box ul li p {
  /* padding: 4px; */
  background: #ffffff;
  border-radius: 5px;
  width: 40px;
  height: 40px;
  border: 1px solid #dbd9d4;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination-box ul li p.active {
  /* padding: 4px; */
  background: #ffffff;
  border-radius: 2px;
  width: 40px;
  height: 40px;
  border: 1px solid #dbd9d4;
  color: #b9b5ab;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
}

.clients-say-right {
  position: relative;
}

.banner-img::before {
  content: "";
  position: absolute !important;
  left: 0;
  right: 0;
  background: #000;
  width: 100%;
  height: 100%;
  opacity: 0.2;
  bottom: 0;
  top: 0;
}

.contact-us-banner::before {
  content: "";
  position: absolute !important;
  left: 0;
  right: 0;
  background: #000;
  width: 100%;
  height: 100%;
  opacity: 0.2;
  bottom: 0;
  top: 0;
}

.laptop-view {
  z-index: 1;
  position: relative;
}

.Rate-Us .style-module_fillIcons__6---A {
  color: #b9b5ab !important;
}

span.formik-errors {
  color: red;
  font-size: 14px;
  margin-top: 5px !important;
  display: block;
}

.img-upld-box .view-img {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.main-category {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.sign-profile .view-img {
  position: absolute;
  left: 0;
  right: 0;
}

.last-menu button {
  margin: 0px 30px;
  font-weight: 100;
  text-decoration: none;
  font-size: 14px;
  border: none;
  color: #fff;
  background: none;
}

.simple-header.black-active .last-menu button {
  color: #000;
}

.post-code-srch {
  border-radius: 0;
}

.post-code-icon {
  border-radius: 0;
  background: #b9b5ab;
  border: 1px solid #b9b5ab;
}

.post-code-types .form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e");
  display: block;
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  appearance: none;
  background-color: var(--bs-body-bg);
  background-image: var(--bs-form-select-bg-img),
    var(--bs-form-select-bg-icon, none);
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: var(--bs-border-width) solid #b9b5ab;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  padding-right: 60px;
}

.post-code-types select {
  padding: 20px 15px;
  border-radius: 0px;
}

.post-code-types select {
  padding: 12px 25px;
  border-radius: 0px;
  color: rgba(7, 21, 35, 0.5);
}

.rated-salons-card .logo-show-salon {
  width: 100px;
  height: 105px;
  object-fit: cover;
}


.pop-input-cat.input-group {
  border: 1px solid #b5b9bd !important;
}

.no-data-main {
  display: flex;
  justify-content: center;

}


.pagination_new .pagination {
  background: #fff;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  border-radius: 10px;
  padding: 10px 10px;
  width: auto;
  display: flex;
  justify-content: center;
}

.pagination_new .pagination li a {
  color: #000;
  padding: 6px 10px;
  text-decoration: none;
}

.pagination_new .active a {
  background: #c4c2c2;
  border-radius: 6px;
}

.pagination_new {
  display: flex;
  justify-content: center;
}

.clients-say-text .star-svg {
  width: 23px;
}
.Salon-Detail-banner-btn a {
    background: #b9b5ab;
    border: 0;
    color: #fff;
    height: 57px;
    margin: 0 20px;
    width: 210px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
}


.address-set {
    margin: auto;
}



/* .media-body.blog-content-html img {
    width: 100%!important;
} */


.left-side-blog {
    cursor: pointer;
}



.brand-logos-box img {
    width: 170px;
    height: 170px;
    object-fit: contain;
}





.side-treatment .owl-nav button span {
  font-size: 0;
}

.side-treatment .owl-nav button span {
  font-size: 0;
}

.side-treatment .slick-prev::before {
  content: "";
  background-image: url("../images/left-slide-icon.svg");
  position: absolute;
  width: 40px;
  height: 40px;
  left: 0;
  right: 0;
  background-size: contain;
  top: 0;
  left: -50px;
  background-repeat: no-repeat;
  bottom: 0;
  border-radius: 140px;
  box-shadow: 0px 5px 14px 0px rgba(0, 0, 0, 0.1);
}

.side-treatment .slick-next::before {
  content: "";
  background-image: url("../images/right-slide-icon.svg");
  position: absolute;
  width: 40px;
  height: 40px;
  background-size: contain;

  top: 0;
  right: -50px;
  background-repeat: no-repeat;
  bottom: 0;
  border-radius: 140px;
  box-shadow: 0px 5px 14px 0px rgba(0, 0, 0, 0.1);
}

.no-review {
    color: #fff;
    font-size: 18px;
    font-weight: 400;
    z-index: 11;
    position: relative;
}

.salon-map img {
    width: 65px;
    height: 65px;
}


.add-hours-btn button {
    background: #071523;
    border: none;
    padding: 10px 37px;
    color: #fff;
    font-size: 15px;
}
.opening-hide {
    display: none;
}
.opening-show {
    display: block;
}


.opening-days-select select.form-control {
    border: 1px solid #b9b5ab;
    background: #fff;
    border-radius: 0;
    height: 50px;
    appearance: auto;
     --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e");
  display: block;
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  appearance: none;
  background-color: var(--bs-body-bg);
  background-image: var(--bs-form-select-bg-img),
    var(--bs-form-select-bg-icon, none);
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: var(--bs-border-width) solid #b9b5ab;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  padding-right: 60px;
}

.Opening-add-btns button {
    width: 50px;
    background: none;
    height: 50px;
      border: 1px solid #b9b5ab;
    margin-right: 10px;
}
.Opening-add-btns button:first-child img {
    width: 30px;
    height: 30px;
}
.Opening-add-btns button:last-child img {
    width: 22px;
    height: 22px;
}


.buisness_form_fields {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.buisness_form_fields p {
  color: #5f6368;

  font-size: 18px;
  font-style: normal;
  position: unset;
  font-weight: 500;
  letter-spacing: -.4px;
  line-height: 42px;
  margin: 0;
  max-width: 100%;
  width: 125px;
}
.buisness_form_fields .form-select {
  border: none;
  border-bottom: 1px solid #b2b2b2;
  border-radius: 0;
  color: #565b738c;
  font-size: 16px;
  padding: 0 0 2px 1px;
  width: 35%;
}
.edit_btns {
  display: flex;
  width: 100px;
}
.edit_btns button {
  background: none;
  border: none;
  padding-right: 5px;
    padding-left: 0;
}
.buisness_form_fields input {
  border: none;
  border-bottom: 1px solid #00000045;
  border-radius: 0;
  color: #565b73;
  font-size: 16px;
  max-width: 100%;
  padding-left: 0;
  width: 72%;
}
.edit_btns img {
  width: 30px;
  height: 30px;
  border: 1px solid #000;
  cursor: pointer;
}
.edit_btns button:first-child img {
  width: 30px;
  height: 30px;
  padding: 4px;
}
.association-sec img {
    width: 100px;
}


.mail-send-footer span {
    position: absolute;
    right: 4px;
    top: 4px;
    bottom: 4px;
}


.Sign-main-text p b {
    font-size: 22px;
    font-weight: bold;
    color: #071523;
    font-family: "RecifeDisplay";
}

.load-main {
  width: 100%;
  height: 100vh;
  background: #00000059;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 111;
}


select.form-select.add_width option {
  color: #000;
}

select.form-select.add_width option:disabled {
  color: #a2a5b2;
  cursor: not-allowed;
}

.mob-show {
    display: none;
}



.slick-slide img {
   
    width: 100%;
    height: 500px;
    object-fit: cover;
}


.main-category .category-select:last-child {
  display: block;
  width: 100%;
}
.new-filed-set {
  margin-top: 30px;
}


.we-recommend-box .col-lg-3:nth-child(2) {
  display: none;
}

.we-recommend-box .col-lg-3:nth-child(9) {
  display: none;
}
.we-recommend-box .col-lg-3:last-child {
  display: none;
}


.new-plan-change h3 {
  font-size: 30px;
  margin-bottom: 10px;
}
/* .we-recommend-box .col-lg-3:first-child {
  display: none;
} */





.dlt-ac-btn button {
    border: 1px solid #b9b5ab;
    background: #b9b5ab;
    box-shadow: 0px 10px 14px 0px rgb(0 0 0 / 14%);
    color: #fff;
    text-align: center;
    font-size: 13px;
    text-transform: capitalize;
    font-weight: 400;
    text-decoration: none;
    padding: 9px 43px;
    width: 100% !important;
    margin-bottom: 4px;
    display: flex;
    justify-content: center;
}

.cmn-doc {
  border: 1px solid #b9b5ab;
  background: #b9b5ab;
  box-shadow: 0px 10px 14px 0px rgb(0 0 0 / 14%);
  color: #fff;
  text-align: center;
  font-size: 13px;
  text-transform: capitalize;
  font-weight: 400;
  text-decoration: none;
  padding: 8px 43px;
  width: fit-content;
  margin: 5px 0;
  display: flex;
  justify-content: center;
}
.cmn-doc img {
  width: 20px;
  margin-left: 10px;
}
.documents-upload .form-group {
  margin-bottom: 10px;
}
.upload-input {
  margin-bottom: 10px;
}

.bg-input-shadow-c {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, .25);
  color: #000;
  display: flex;
  font-family: DM Sans;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  justify-content: space-between;
  letter-spacing: -.02em;
  line-height: 24px;
  min-height: 44px;
  margin-top: 20px;
  margin-left: 2px;
  margin-right: 2px;
  padding: 10px;
}
.group-icon-text {
  display: flex;
  width: 100%;
}

.bg-input-shadow-c p {
  margin: 0;
  font-family: "Futura Md BT" !important;
  font-size: 14px;
  font-weight: 500;
}
.group-icon-text img {
  padding-right: 20px;
  width: 40px;
  height: 25px;
  object-fit: scale-down;
}

.padf {
font-weight: 600;
}

.certificate {
  color: #071523;
  font-size: 16px!important;
  font-weight: 400!important;
  line-height: 24px!important;
  text-transform: capitalize!important;
  font-family: "Futura Md BT" !important;
  letter-spacing: -0.3px;
  margin-bottom: 0!important;
}
.bg-input-shadow-c svg path {
    fill: #b9b5ab;
}
.bg-input-shadow-c svg {
margin-right: 20px;
}
.overflow_prop-170 {
    height: 175px;
  
    overflow-y: scroll;
        
    padding: 0 10px;
    margin-top: 19px;
    position: relative;
}
.overflow_prop-170.no_data_scroll {
border: 2px dashed #b9b5ab;
}
.overflow_prop-170::-webkit-scrollbar {
 display: none;
}
.dlt-ac-btn.upload-btn button {
    position: absolute;
    bottom: 8px;
    width: 155px !important;
    left: 0;
    right: 0;
    margin: auto;
}
.brand-logos-box {
  cursor: pointer;
}

.overflow_prop-170 .upload-place {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 0;
    bottom: 0;
}

.view-link-btn {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.register-pop-btn button {
    background: #000;
    border: none;
    padding: 10px 35px;
}